import theme from "./assets/theme/theme";
import {
  getFirebaseConfig,
  getLogOutTimerConfig,
  getMiddleHost,
} from "./utils/environment";

export enum DocTypes {
  i20 = "I-20",
  // passportIndia = "Passport India",
  passport = "Passport",
  visaEU = "Visa EU",
  visaUK = "Visa UK",
  visaIsrael = "Visa Israel",
  visaUSA = "Visa USA",
  i797 = "I-797",
  dlUSA = "Drivers License USA",
  ead = "EAD",
  resume = "Resume",
  degreeCert = "Degree Certificate",
  i94 = "I-94",
  aadharCard = "Adhaar Card",
  panCard = "PAN Card",
  travelHistoryUSA = "Travel History USA",
  others = "Others",
}

const pages = {
  legalSupport: "legal-support",
  travelHistory: "travel-history",
  o1eb1: "o1-eb1",
  signup: "signup",
  login: "login",
  forgotPassword: "forgot-password",
  emailVerify: "email-verify",
  sharedDocuments: "shared-documents",
  dashboard: "dashboard",
  viewUploadDocs: "view-upload-docs",
  userProfileSetup: "user-profile-setup",
  visaFormAssistance: "visa-form-assistance",
  faqs: "faqs",
  privacyPolicy: "privacy-policy",
  terms: "terms",
  enrollMFA: "enroll-mfa",
  verifyMfa: "verify-mfa",
  termsAndCondition: "terms-and-condition",
  payment: "payment",
  paymentUser: "paymentUser",
  getExpertHelp: "get-expert-help",
  verifyMfaDelete: "verify-mfa-delete",
  deleteConfirmationPage: "account-delete-confirmation",
}

const constants = {
  maxLimitToUploadDocAtTime: 20,
  document: {
    expiryFieldTitle1: 'Expiry Date',
    expiryFieldTitle2: 'Issue Date',
    expiryFieldTitle3: 'Admit Until',
    expiryFieldTitle4: 'Valid Until',
  },
  inputText: {
    minInputLimit: 5,
    maxInputLimit: 40,
    specialChars: new RegExp(/[&#*^%?()[\]{}!+=:;|/~`,<>\\$'"]/)
  },
  dateFormat: "PP",
  docuCombLink: "https://app.immplify.com",
  apiLink: "https://app.immplify.com/api",
  blogs: {
    renewPassport: "https://immplify.com/posts/how-to-renew-an-indian-passport-in-the-us"
  },
  firebaseConfig: getFirebaseConfig(),
  docTypeSlugs: {
    others: "others",
    "i-797": "i-797",
    "i-20": "i-20",
    ead: "ead",
  },
  docTypes: {
    i20: "I-20",
    // passportIndia: "Passport India",
    passport: "Passport",
    visaEU: "Visa EU",
    visaUK: "Visa UK",
    visaIsrael: "Visa Israel",
    visaUSA: "Visa USA",
    i797: "I-797",
    dlUSA: "Drivers License USA",
    ead: "EAD",
    resume: "Resume",
    degreeCert: "Degree Certificate",
    i94: "I-94",
    i140: "I-140",
    ssn: "SSN",
    aadharCard: "Adhaar Card",
    panCard: "PAN Card",
    travelHistoryUSA: "Travel History USA",
    null: "Others",
  },
  sensitiveDataItems: [
    "license no.",
    "uscis #",
    "uscis",
    "receipt no.",
    "visa no.",
    "passport no.",
    "adhaar number",
    "permanent account number (pan)",
    "i-94 number",
    "sevis id",
  ],
  storageUrlReplace: "storage.googleapis.com",
  storageHostReplaceWith: "storage.cloud.google.com",
  collections: {
    docShareRequest: "doc-share-requests",
    documents: "documents",
    users: "users",
    usersMeta: "users-metadata",
  },
  customCardTemplates: {
    // Important: name has to be same as key of object
    default: { name: "default", color: "#FFEDAF", cardBG: theme.palette.primary.main, filterTitle: "Default Template" },
    pastAddress: { name: "pastAddress", color: "#EFECFB", cardBG: theme.palette.success.main, modalTitle: "Past Address Template", filterTitle: "Past Address Template" },
    employer: { name: "employer", color: "#E6E8D7", cardBG: theme.palette.secondary.main, modalTitle: "Employment Template", filterTitle: "Employment Template" }
  },
  pageTitles: {
    [pages.legalSupport]: "Immplify - Legal Support",
    [pages.travelHistory]: "Immplify - Travel History",
    [pages.o1eb1]: "Immplify - O1/EB1",
    [pages.signup]: "Immplify - Sign Up",
    [pages.login]: "Immplify - Log In",
    [pages.forgotPassword]: "Immplify - Forgot Password",
    [pages.emailVerify]: "Immplify - Email Verify",
    [pages.sharedDocuments]: "Immplify - Shared Documents",
    [pages.dashboard]: "Immplify - Dashboard",
    [pages.viewUploadDocs]: "Immplify - Document Gallery",
    [pages.userProfileSetup]: "Immplify - User Profile Setup",
    [pages.visaFormAssistance]: "Immplify - Visa Form Assistance",
    [pages.faqs]: "Immplify - FAQs",
    [pages.privacyPolicy]: "Immplify - Privacy Policy",
    [pages.terms]: "Immplify - Terms of Service",
    [pages.enrollMFA]: "Immplify - MFA",
    [pages.termsAndCondition]: "Immplify - Terms & Condition",
  },
  pages,
  middleHost: getMiddleHost(),
  logOutTimer: getLogOutTimerConfig(),
  publicAssets: {
    privacyPolicyStage: `${getMiddleHost()}/public-assets?assetID=privacy-policy`,
    privacyPolicyProd: `${getMiddleHost()}/public-assets?assetID=privacy-policy`,
    termsOfServiceStage: `${getMiddleHost()}/public-assets?assetID=terms-and-conditions`,
    termsOfServiceProd: `${getMiddleHost()}/public-assets?assetID=terms-and-conditions`
  },
  priorityOrder: [""],
  folders : [""],
  VisaStatusList : [""],
  O1EB1FoldersListAuto : [""],
  O1EB1FoldersListManual : [""],
  tagKeys: [""],
};

export default constants;

// DL_USA = Expiry Date
// EAD = Expiry Date
// I-797 = Expiry Date
// PP_India = Expiry Date
// Shengen Visa = Expiry Date
// UK Visa = Expiry Date
// USA Via = Expiry Date
// I20 = Issue Date

import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Badge,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import Logo from "../Shared/Logo";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { usePaymentContext } from "../../contexts/PaymentContext";

import { DCButton, DcIconBox, DcIconButton } from "../../assets/theme/theme";
import Notifications from "./Notifications";
import { Box } from "@mui/system";
import DCAvatar from "../Shared/DCAvatar";
import NotificationIcon from "../Shared/Icons/NotificationIcon";
import { useAuth } from "../../contexts/AuthContext";
import { get, map } from "lodash";
import {
  NotificationsProvider,
  useNotifications,
} from "../../contexts/NotificationsContext";
import { useHistory } from "react-router-dom";
import { useSidebarContext } from "../../contexts/SidebarContext";

import { useScanningTipsModalContext } from "../../contexts/ScanningTipsModalContext";
import TipsIcon from "../Shared/Icons/TipsIcon";
import MenuIcon from "../Shared/Icons/MenuIcon";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase";
import UserSettingsIcon from "../Shared/Icons/UserSettingsIcon";
import LogoutIcon from "../Shared/Icons/LogOutIcon";
import { Close } from "@mui/icons-material";
import { useUserContext } from "../../contexts/UserContext";
import { isSubscriptionActive } from "../../utils/userUtils";
import useDrivePicker from "react-google-drive-picker";
import { useDocumentUploadContext } from "../../contexts/DocumentUploadContext";

export interface Props {}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}
export interface Props {
  pageId: string;
}

const NotificationBadgeIcon: React.FC = () => {
  const { notifications } = useNotifications();
  return (
    <Badge
      badgeContent={notifications.filter((note) => note.seen !== true).length}
      color="primary"
    >
      <NotificationIcon
        className={
          notifications.filter((note) => note.seen !== true).length > 0
            ? "notification-ring-animation"
            : ""
        }
      />
      {/* <NotificationIcon

      /> */}
    </Badge>
  );
};

const API_KEY = "AIzaSyAn1JT_vDSYcPSw2MBG3rWxQs61MXkjzFE";
const CLIENT_ID =
  "127387328738-gm86fvj82snribei58a61o9os4057h79.apps.googleusercontent.com";
const DISCOVERY_DOCS = [
  "https://www.googleapis.com/discovery/v1/apis/drive/v3/rest",
];
const SCOPES = "https://www.googleapis.com/auth/drive.readonly";

const Header: React.FC<Props> = (props) => {
  const theme = useTheme();
  const [openPicker, authResponse] = useDrivePicker();
  const { uploadFiles } = useDocumentUploadContext();
  const { toggleSidebar } = useSidebarContext();
  const { logout, currentUser } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [avtarEl, setavtarEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const [isSupport, setIsSupport] = useState<boolean>(false);
  const { activePayment } = usePaymentContext();
  const { openScanningTipsModal } = useScanningTipsModalContext();
  const { user } = useUserContext();
  const open = Boolean(anchorEl);
  const profileMenuOpen = Boolean(avtarEl);
  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    logEvent(analytics, "click_profile");
    setavtarEl(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    logEvent(analytics, "click_notification");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setavtarEl(null);
  };

  const onLogoutClick = React.useCallback(() => {
    logEvent(analytics, "click_logout");
    logout();
  }, [logout]);

  const onUserProfileClick = React.useCallback(() => {
    history.push("/profile/setup/1");
  }, [history]);

  useEffect(() => {
    setIsSupport(true);
  }, []);

  // const handleSnackbarClick = () => {
  //   setOpenSnackbar(true);
  // };
  // const handleSnackbarClose = (
  //   event?: React.SyntheticEvent | Event,
  //   reason?: string
  // ) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }

  //   setOpenSnackbar(false);
  // };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          boxShadow: theme.shadows[1],
          background: theme.palette.common.white,
        }}
      >
        <NotificationsProvider>
          <Toolbar disableGutters>
            <Box
              sx={{
                flexBasis: drawerWidth,
                height: "64px",
                alignItems: "center",
                paddingLeft: "16px",
                display: "flex",
                [theme.breakpoints.down("md")]: {
                  flexBasis: 232,
                },
              }}
            >
              <Box className="logo-img" sx={{ display: { md: "flex" }, flexGrow: 1, mb: '6px'}}>
                <Logo width={157} />
              </Box>
              <IconButton
                sx={{
                  borderRadius: "12px",
                  overflow: "hidden",
                  [theme.breakpoints.down("md")]: { mx: "auto" },
                  marginLeft: 1,
                }}
                aria-label="open drawer"
                onClick={toggleSidebar}
                edge="start"
              >
                <MenuIcon fontSize="small" />
              </IconButton>
              {/* {activePayment?.status !== "success" &&
                isSupport &&
                parseInt(window.localStorage.getItem("loginCount") || "0") >
                3 && (
                  <Box className="support-main-section"> 
                    <Box className="support-inner" sx={{ marginLeft: 1, display: "flex" }}>
                      <Box
                        className="support-inner-section"
                        onClick={() => history.replace("/payment")}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          position: "relative",
                          cursor: "pointer",
                          borderTopLeftRadius: 999,
                          borderBottomLeftRadius: 999,
                          pl: 2,
                          py: 0.725,
                          color: theme.palette.primary.main,
                          bgcolor: theme.palette.primary.light,
                          border: 0,
                          fontWeight: "700",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <Typography>
                          Support us and thousand of immigrants in their journey
                        </Typography>
                      </Box>
                      <Box
                          className="close-icon"
                        sx={{
                          borderBottomRightRadius: 999,
                          borderTopRightRadius: 999,
                          paddingTop: 0.725,
                          paddingRight: 1,
                          pl: 1,
                          cursor: "pointer",
                          color: theme.palette.primary.main,
                          bgcolor: theme.palette.primary.light,
                        }}
                        onClick={() => setIsSupport(false)}
                      >
                        <Close fontSize="small" />
                      </Box>
                    </Box>
                  </Box>
                )} */}
            </Box>
            <Box display="flex" marginLeft="auto">
              {/* <Button
                variant="outlined"
                sx={{ mr: 4 }}
                onClick={handleSnackbarClick}
              >
                Open success snackbar
              </Button>
              <DCSnackbar
                open={openSnackbar}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                title="New Notification"
                body="You have a new notification"
              /> */}
              <DcIconBox className="tips-btn-main">
                <DCButton
                    className="tips-btn"
                  onClick={() => {
                    logEvent(analytics, "click_scanning_tips");
                    openScanningTipsModal(0);
                  }}
                  startIcon={<TipsIcon fontSize="small" />}
                  sx={{
                    borderRadius: 8,
                    px: 2,
                    py: 0.725,
                    bgcolor: theme.palette.primary.light,
                    border: 0,
                    ":hover": {
                      bgcolor: theme.palette.primary.light,
                      transform: "scale(1.05)",
                      transition: "ease-in-out .15s",
                    },
                  }}
                >
                  <Typography className="text-tips">Scanning Tips</Typography>
                </DCButton>

                {/* <Tooltip title={"Scanning Tips"} placement="left" arrow>
                <DcIconButton
                  size="medium"
                  aria-label="Open Scanning tips"
                  onClick={() => openScanningTipsModal(0)}
                >
                  <img src={Bulb} alt="scanning tips icon" />
                </DcIconButton>
              </Tooltip> */}
              </DcIconBox>
              <DcIconBox ml={1} className="header-notification-bell">
                <Tooltip title={"Notifications"} placement="bottom" arrow>
                  <DcIconButton
                    className="notification-bell"
                    size="medium"
                    aria-label="show new notifications"
                    onClick={handleClick}
                  >
                    <NotificationBadgeIcon />
                  </DcIconButton>
                </Tooltip>
              </DcIconBox>
              <DcIconBox ml={1.5} mr={2}>
                <DcIconButton
                  size="large"
                  aria-label="show more"
                  aria-haspopup="true"
                  color="inherit"
                  onClick={handleProfileClick}
                >
                  <DCAvatar
                    name={user?.firstName+" "+user?.lastName}
                    email={user?.email as string}
                    imgSrc={get(currentUser, "photoURL") as string}
                    premium={isSubscriptionActive(user)}
                  />
                </DcIconButton>
              </DcIconBox>
            </Box>
          </Toolbar>

          <Notifications
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            pageId={props.pageId}
          />
        </NotificationsProvider>
        <Menu
          anchorEl={avtarEl}
          open={profileMenuOpen}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem style={{ width: 150 }} onClick={onUserProfileClick}>
            <ListItemIcon style={{ minWidth: "unset" }}>
              <UserSettingsIcon />
            </ListItemIcon>
            <ListItemText>User Profile</ListItemText>
          </MenuItem>

          <MenuItem style={{ width: 150 }} onClick={onLogoutClick}>
            <ListItemIcon style={{ minWidth: "unset" }}>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </AppBar>
    </Box>
  );
};

export default Header;

import React, { useCallback, useEffect } from "react"
import { IFolder } from "./DocumentContext"
import constants from "../constants"
import { IDocument, IUser } from "../firebaseTypes"
import FolderPassportIcon from "../components/Shared/Icons/FolderPassportIcon"
import { debounce, groupBy } from "lodash"
import { sanitizeDocuments, sortDocuments } from "../utils/documentUtils"
import { useAuth } from "./AuthContext"
import { useUserContext } from "./UserContext"
import { IGeO1EB1tDocumentsResponse } from "../middleTypes"
import { rtdb } from "../firebase"
import { onValue, ref } from "firebase/database"

export interface O1EB1DocumentsContextProps {
  O1EB1AutoFolders: IFolder[],
  O1EB1ManualFolders: IFolder[],
  O1EB1AutoDocs: IDocument[],
  O1EB1ManualDocs: IDocument[],
  allO1EB1Folders: IFolder[],
  allO1EB1Docs: IDocument[],
  initalizedDocumentsAndFolders: boolean,
  O1EB1Progress: number,
}

const defaultValues: O1EB1DocumentsContextProps = {
  O1EB1AutoFolders: [],
  O1EB1ManualFolders: [],
  O1EB1AutoDocs: [],
  O1EB1ManualDocs: [],
  allO1EB1Folders: [],
  allO1EB1Docs: [],
  initalizedDocumentsAndFolders: false,
  O1EB1Progress: 0
}

const O1EB1DocumentsContext = React.createContext(defaultValues)

export function useO1EB1DocumentsContext() {
  return React.useContext(O1EB1DocumentsContext)
}

export const O1EB1ContextProvider: React.FC = (props) => {
  const { currentUser } = useAuth();
  const { user } = useUserContext();
  const [O1EB1AutoDocs, setO1EB1AutoDocs] = React.useState<IDocument[]>(defaultValues.O1EB1AutoDocs);
  const [O1EB1ManualDocs, setO1EB1ManualDocs] = React.useState<IDocument[]>(defaultValues.O1EB1ManualDocs);
  const [O1EB1AutoFolders, setO1EB1AutoFolders] = React.useState<IFolder[]>(defaultValues.O1EB1AutoFolders);
  const [O1EB1ManualFolders, setO1EB1ManualFolders] = React.useState<IFolder[]>(defaultValues.O1EB1ManualFolders);
  const [allO1EB1Folders, setAllO1EB1Folders] = React.useState<IFolder[]>([]);
  const [allO1EB1Docs, setAllO1EB1Docs] = React.useState<IDocument[]>([]);
  const [initalizedDocumentsAndFolders, setInitalizedDocumentsAndFolders] = React.useState<boolean>(defaultValues.initalizedDocumentsAndFolders);
  const [O1EB1Progress, setO1EB1Progress] = React.useState<number>(defaultValues.O1EB1Progress);
  const [isFetchingO1EB1Documents, setIsFetchingO1EB1Documents] = React.useState<boolean>(false);
  const [isActive, setIsActive] = React.useState<boolean>(false);

  function roundToNearestTen(value: number): number {
    return Math.round(value / 10) * 10;
}

// Automatic Folders including others
const getO1EB1Folders = useCallback((autoDocs: IDocument[], manualDocs: IDocument[]) => {
  const groupedByDocType = groupBy(autoDocs, "doc_type")

  const autoFolders = constants.O1EB1FoldersListAuto.map((docType) => {
    const docs = groupedByDocType[docType] || []
    return {
      count: docs.length,
      title: docType,
      docType,
      icon: <FolderPassportIcon />,
      docs: sortDocuments(docs),
    }
  })

  const manualFolder = constants.O1EB1FoldersListManual.map((docType) => {
    const groupedByDocType = groupBy(manualDocs, "doc_type");

    const docs = groupedByDocType[docType] || []
    return {
      count: docs.length,
      title: docType,
      docType,
      icon: <FolderPassportIcon />,
      docs: sortDocuments(docs),
    }
  })

  // sort folders depending on the number of documents
  autoFolders.sort((a, b) => b.count - a.count)
  manualFolder.sort((a, b) => b.count - a.count)

  const allFolders = autoFolders.concat(manualFolder);
  // sort all folders depending on the number of documents
  allFolders.sort((a, b) => b.count - a.count)

  setO1EB1AutoFolders(autoFolders);
  setO1EB1ManualFolders(manualFolder);
  setAllO1EB1Folders(allFolders);

  // round the progress nearest to multiple of 10, like it will go 0, 10, 20, 30 ... 100 no float values
  setO1EB1Progress(roundToNearestTen(parseFloat((allFolders.filter(folder => folder.count > 0).length / allFolders.length * 100).toFixed(2))));
}, []);
  
  const getO1EB1Documents = useCallback(async () => {
    setInitalizedDocumentsAndFolders(false);
    if (!currentUser?.uid) return;
    if (!user?.id) return;
    if (isFetchingO1EB1Documents) return;
    try {
      console.log("onValue start");
      setIsFetchingO1EB1Documents(true);
      const url = `${constants.middleHost}/users/${currentUser.uid}/documents/o1-eb1?page=1&itemsPerPage=100`;
      const token = await currentUser.getIdToken();
      // fetch the image
      const res: Response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
      });
      const { data }: IGeO1EB1tDocumentsResponse = await res.json();
      const O1EB1AutoDocs = sanitizeDocuments(data.auto, user as IUser);
      const O1EB1ManualDocs = sanitizeDocuments(data.manual, user as IUser);

      getO1EB1Folders(O1EB1AutoDocs, O1EB1ManualDocs);
      setAllO1EB1Docs([...O1EB1AutoDocs, ...O1EB1ManualDocs]);
      setO1EB1AutoDocs(O1EB1AutoDocs);
      setO1EB1ManualDocs(O1EB1ManualDocs);
      setIsFetchingO1EB1Documents(false);
      setInitalizedDocumentsAndFolders(true);
      console.log("onValue end");
    } catch (error) {
      console.error(error);
    }
  }, [currentUser, getO1EB1Folders, isFetchingO1EB1Documents, user]);

  const debouncedOnValue = debounce(getO1EB1Documents, 500, {
    leading: false,
  });

  const init = useCallback(async () => {
    // Register onValue
    if (!currentUser?.uid) return;
    if (!user?.id) return;
    const documentsRef = ref(rtdb, `users/${currentUser.uid}/documents`);
    // important: onValueCallback has to be throttled because we don't want to add load to fetch documents api in middle server
    // allow one call in one second
    const unsubscribe = onValue(documentsRef, debouncedOnValue);
    return unsubscribe;
  }
    , [currentUser?.uid, user?.id]);

  useEffect(() => {
    init();
  }, [init]); 

  return <O1EB1DocumentsContext.Provider value={{
    O1EB1AutoFolders,
    O1EB1ManualFolders,
    O1EB1AutoDocs,
    O1EB1ManualDocs,
    allO1EB1Folders,
    allO1EB1Docs,
    initalizedDocumentsAndFolders,
    O1EB1Progress: O1EB1Progress
  }}>
    {props.children}
  </O1EB1DocumentsContext.Provider>
}
import { StripeError } from "@stripe/stripe-js";

export interface ITaskRef {
  task_id: string; // task_id: BnApXhOLJOuKVGGAv8WK
  task_path: string; // task_path: tasks/2023-07-06/daily-tasks
}
export interface ITask {
  doc_id?: string;
  expiry_duration: string;
  task_ref: string;
  custom_doc_path?: string;
  url: string;
}

// shrinked document are the selected field which we want to alias
export interface IShrinkedDocument {
  doc_image: string;
  doc_title?: string;
  doc_image_path: string;
  doc_path: string;
  doc_thumbnail_url: string;
  doc_thumbnail_path: string;
  doc_bucket_url: string;
  doc_name: string;
  doc_info: {
    value: any;
    raw_value?: any;
    manual_value?: any;
  }[],
  user?: {
    first_name: string;
    last_name: string;
  };
  local_file_name?: string;
  vault_hash?: string;
}

export interface ITag {
  _id: string;
  name: string;
  type: string;
}

export interface IDocument {
  doc_image: string;
  doc_title?: string;
  doc_image_path: string;
  doc_path: string;
  doc_size: number;
  doc_thumbnail_url: string;
  doc_thumbnail_path: string;
  doc_bucket_url: string;
  doc_name: string;
  doc_name_autogenerated?: string,
  doc_type: string;
  doc_info?: IDocumentField[];
  doc_ownership?: boolean;
  doc_format?: string;
  notifications?: {
    type: INotificationsEnum;
    timeStamp: number;
    archived: boolean;
    seen: boolean;
  }[];
  uid?: string;
  id: string;
  taskRef?: ITaskRef[];
  tags?: ITag[];

  // hack for storage urls
  hack_thumbnail_url?: string;
  hack_doc_url?: string;
  user?: {
    first_name: string;
    last_name: string;
  };
  local_file_name?: string;
  created_time?: number;
  f_manually_edited_doc_name?: boolean;
  f_system_has_added_doc_name?: boolean;
  vault_hash?: string;
}

export interface ICustomDocument {
  attached_document_id?: string;
  template?: string;
  doc_name: string;
  doc_type: string;
  doc_title: string;
  doc_info?: IDocumentField[];
  doc_ownership?: boolean;
  doc_format?: string;
  uid: string;
  created_time?: number;
  taskRef?: ITaskRef[];
  id?: string;
}

export interface IFirebaseTimestamp {
  seconds: number;
  _seconds?: number;
}

export type ITrackDateTuple = [Date, string] | [];

export interface ITrackingDateCheckbox {
  value: ITrackDateTuple;
  checked: boolean;
  taskRef?: ITaskRef;
}

export type ITrackingDateKey =
  | "Checkbox1d"
  | "Checkbox1m"
  | "Checkbox2m"
  | "Checkbox4m"
  | "Checkbox8m";

export type ITrackingDates = Record<ITrackingDateKey, ITrackingDateCheckbox>;

export interface IDocumentField {
  date?: boolean;
  address?: boolean;
  zipcode?: string;
  apartment?: string;
  title: string; // view purpose
  description?: string;
  value: any;
  raw_value?: any;
  manual_value?: any;
  manual?: boolean; //represents manually edited field
  priority?: number;
  summarize?: number;
  mandatory?: number;
  trackingDates?: ITrackingDates;
}

export type ISharePermissions = "viewOnly" | "download";

export interface IShareDoc {
  uid: string;
  id: string;
  doc_name: string;
  doc_type: string;
  doc_format: string;
  doc_thumbnail_path: string;
  doc_path: string;
  is_expired: boolean;
}

export interface IShareReq {
  documents: IShareDoc[];
  emails: string[];
  status: string;
  created_at: any;
  shared_by: {
    display_name: string;
  };
  redirect_host: string;
  permission: ISharePermissions;
  signedURLs?: string[];
  signedThumbnailURLs?: string[];
}

export enum INotificationsEnum {
  "edit-action-required" = "edit-action-required",
  "doc-expiry" = "doc-expiry",
  "doc-relation" = "doc-relation",
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  zipCode: string;
  nationality: string;
  state: string;
  country: string;
  docSavingPreference: {
    "cloud-storage": boolean;
    "email-chain": boolean;
    "file-folder": boolean;
    "dont-know": boolean;
  };
  customFolders: ICustomFolder[];
  travelHistoryPath?: string;
  travelHistoryFormat?: string;
  totalLogin: number
  subscription: {
    status: "trialing" | "active" | "incomplete"
  },
  o1eb1?: boolean;
}

export interface ICustomFolder {
  name: string;
}

export interface IUserMetadata {
  uid: string;
  tokens?: {
    [key: string]: boolean;
  };
}

export interface IFirebaseDate {
  seconds: number;
  nanoseconds: number;
  _seconds: number;
  _nanoseconds: number;
}

export interface IResult {
  success: boolean;
  error: string;
  result?: any;
}
export interface ITravelRecord {
  departure: string;
  departure_date: string;
  arrival: string;
  arrival_date: string;
  flag?: string;
}

export interface ITravelHistory {
  id?: string;
  arrival_date: string;
  arrival_loc: string; // airport code, value could be "none" | "Unavailable" | code from assets/usaAirportData
  automated: boolean;
  country: string;
  departure_date: string;
  departure_loc: string; // airport code, check in assets/usaAirportData
  visa_status: string;
  isPresent: boolean;
  isUpdateLocationPromptSkipped?: boolean;
}

export interface ITravelHistoryExtended extends ITravelHistory {
  arrival_loc_label: string;
  departure_loc_label: string;
  hasError: boolean;
  arrival_date_parsed: Date | null;
  departure_date_parsed: Date | null;
};

export type PaymentStatus =
  | "pending"
  | "success"
  | "error"
  | "cancel"
  | "user-canceled";

export type SubscriptionPlan = "Early Adoption Plan"
export type LoadingStatus = "loading" | "empty-results" | "results" | "error" | "none"

export interface IPaymentHistory {
  id?: string;
  paymentDate: number;
  expireDate: number;
  userCanceledDate?: number;
  price: number;
  priceId: string;
  status: PaymentStatus;
  subscriptionPlan: SubscriptionPlan,
  currency: "US Dollars"
  error?: StripeError;
}
